import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { useEffect } from "react";

function Conditions() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window);
  }, []);

  return (
    <div className="Conditions">
      <Helmet>
        <title>Sizefire | Conditions</title>
      </Helmet>
      <div className="Obsah">
        <h1>Terms & Conditions</h1>
        <br />
        <h3>
          General terms and conditions for the sale of online banner sets at
          Sizefire.com
        </h3>
        <p>
          THESE ARE THE TERMS AND CONDITIONS FOR Sizefire.com and the sale of
          its product "Banner Sets".
        </p>
        <h3>
          I. Introductory provisions and explanation of the purpose of the
          General Terms and Conditions
        </h3>
        <p>
          1. These general terms and conditions (hereinafter referred to as
          "TOS") apply to the purchase of online banner sets (hereinafter
          referred to as "set", "digital content" or "product") via the web
          interface www.sizefire.com operated by Ing. Jan Tichavsky, ID:
          08671231, (hereinafter also referred to as "Seller").
        </p>
        <p>
          2. The purchase of the set is based on a purchase contract concluded
          between the Seller and the Buyer. The process of concluding the
          purchase contract is described in detail in Article III. TOS. This TOS
          is a document that forms an integral part of the Purchase Agreement
          and details and explains the rights and obligations of both parties to
          the Purchase Agreement, i.e. the Buyer and the Seller. Provisions in
          the Purchase Contract that differ from the provisions of the TOS shall
          prevail (i.e. the text of the Purchase Contract shall prevail over the
          text of the TOS). The TOS also addresses certain other issues related
          to the purchase of the set or the use of the www.sizefire.com website.
        </p>
        <p>
          3. The TOS contains information that you need to have before you buy
          the set. Please read the TOS carefully and contact me if you have
          comments or questions about them before ordering a set. Please refer
          to Article II for contact information. TOS. Mutual communication is
          the best way to eliminate any future confusion between us. By clicking
          the "Pay" button, you are signaling to me that you have seen and read
          the TOS and agree to the course of dealing as described in the TOS.
          You are also signaling that you are serious about your order and that
          you, like me, are prepared to fulfill your obligations under the
          subsequent purchase contract (i.e., I, as the Seller, will primarily
          deliver the set to you exactly as described in the set specification
          on the web interface and you, as the Buyer, will pay the agreed price
          for the delivered set).
        </p>
        <p>
          4. All essential information is available to you in the TOS. I know
          that there is not much of it, so here is the table of contents for
          quick reference:
        </p>
        <p>
          I. Introduction and explanation of the purpose of the TOS
          <br />
          II. Important terms (definitions)
          <br />
          III. Order and conclusion of the purchase contract
          <br />
          IV. Price of products and payment
          <br />
          V. Delivery terms
          <br />
          VI. Details of the functionality of the digital content and its
          interaction with the hardware and software, user account
          <br />
          VII. Withdrawal from the purchase contract
          <br />
          VIII. Warranty, rights of defective performance
          <br />
          IX. Handling of complaints, resolution of consumer disputes
          <br />
          X. Protection of Intellectual Property
          <br />
          XI. Final provisions
        </p>
        <h3>II. Important terms (definitions)</h3>
        <p>
          1. SELLER. The Seller is:
          <br />
          Ing. Jan Tichavský
          <br />
          ID: 08671231
          <br />
          place of business Hrubého 1202/8, 182 00, Praha 8 - Kobylisy
          <br />
          Delivery address: Hrubého 1202/8, 182 00, Praha 8 - Kobylisy
          <br />
          E-mail delivery address: support@sizefire.com
        </p>
        <p>
          2. BUYER. The Buyer is the one who, through the web interface
          www.sizefire.com, concludes a purchase contract with me, as the
          Seller, by which he/she purchases the set. The Buyer can be an
          entrepreneur (an entrepreneurial natural person often also referred to
          as a self-employed person or a legal entity, e.g. a limited company or
          a joint stock company) or a consumer.
        </p>
        <p>
          3. CONSUMER. A consumer is defined by law as a natural person who is
          not acting in the course of his business or in the course of his
          independent exercise of his profession. If you are a natural person
          and you include your business registration number in your order, you
          will be deemed to be entering into the purchase contract as a business
          and not as a consumer.
        </p>
        <p>
          4. DISTANCE CONTRACT. It is a contract of sale if it is concluded
          through the MEANS OF REMOTE COMMUNICATION, i.e. it is concluded
          without the Seller and the Buyer having to meet in person, because it
          is concluded through a web interface (based on an order made directly
          on the website www.sizefire.com by filling in the necessary data by
          the Buyer).
        </p>
        <p>
          5. APPLICABLE LAW. These are the applicable legal regulations
          governing the relationship between the Buyer and the Seller. In the
          Czech Republic, this is in particular Act No. 89/2012 Coll., the Civil
          Code (hereinafter referred to as the "Civil Code") and in cases where
          the Buyer is a consumer, it is also Act No. 634/1992 Coll., on
          Consumer Protection.
        </p>
        <h3>III. Order and conclusion of the purchase contract</h3>
        <p>
          1. The buyer orders the banner set via the web interface, i.e. via the
          ordering system, by clicking on the "buy" button.
        </p>
        <p>
          2. DESCRIPTION OF THE SET After selecting the specific template of the
          set that the user wants to edit, the exact graphic design and the
          number of sizes of the set are displayed in the web interface of the
          editor, these are displayed in 10 sizes (120x600, 160x600, 200x200,
          250x250, 300x250, 300x600, 336x280, 468x60, 728x90, and 970x90). All
          of these sizes are provided as PNG downloads in the package. Only this
          one product, a downloadable banner set of 10 sizes in PNG, is offered
          on the Sizefire website. The actual price for the download set is
          above the "buy" button, and the buyer agrees to the price at the time
          the button is clicked.
        </p>
        <p>
          3. ORDERING THE SET To order the set via the web interface of the
          shop, click on the "buy" button. If you wish to receive a confirmation
          of your purchase, please fill in your email before ordering, to which
          a confirmation will be sent after payment for the set.
        </p>
        <p>
          4. CANCELLATION OF ORDER The order is cancelled when the payment for
          the ordered set is not made, i.e. when the payment gateway limit
          expires or the Buyer cancels.
        </p>
        <p>
          5. The Purchase Agreement and the TOS are concluded in English. The
          Contract is concluded in electronic form and consists of the date of
          your payment of the order and your email, its acceptance by me and
          this TOS. I archive the contract in electronic form, it is not
          accessible to third parties.
        </p>
        <h3>IV. Set price and payment</h3>
        <p>
          1. SET PRICE. The price of the set is inclusive of VAT. No additional
          charges are added to the price shown on the web interface. The price
          is valid for as long as it is displayed on the web interface. Due to
          the nature of the products, there are no shipping or other costs
          associated with their delivery. The price calculated before pressing
          the "buy" button is already the final price.
        </p>
        <p>
          2. The agreed purchase price is the price indicated for the set at the
          time you submit your order by clicking the "buy" button. If there is
          an obvious error in the price on the web interface (this primarily
          means a typo, a mistake in entering the price) or a similar error in
          the process of concluding the purchase contract, then I am not obliged
          to deliver the product to you at such an obviously incorrect price,
          even if there is an automatic confirmation of acceptance of the order.
          In the event that you have already paid the apparently erroneous
          price, I am entitled to withdraw from the purchase contract. If there
          is a change in the purchase price between the dispatch of your order
          and our confirmation of it, the purchase price valid at the time of
          dispatch of the order shall apply, unless otherwise expressly agreed
          between us.
        </p>
        <p>
          3. Unless otherwise expressly agreed between us, I am obliged to
          deliver the set to you only after full payment of the agreed purchase
          price.
        </p>
        <p>
          4. METHOD OF PAYMENT. The purchase price can be paid in the following
          ways: Directly by bank transfer to our bank account via the payment
          gateway GoPay or by credit card. All matters relating to the
          processing of sensitive payment data such as card number are handled
          by GoPay, Sizefire does not have this data and therefore does not
          handle it in any way. The purchase price is payable in US dollars
          (USD). You should be able to pay via these methods: VISA, VISA
          Electron, MasterCard, MasterCard Electronic, Maestro, GoPay.
        </p>
        <p>
          5. MATURITY OF THE PURCHASE PRICE. In the case of a cashless transfer,
          the purchase price is payable immediately. The purchase price is paid
          when the relevant amount is credited to my bank account. The final
          invoice (tax document) is always issued on the date the amount is
          credited to my bank account. Section 2119 (1) of the Czech Civil Code
          does not apply.
        </p>
        <h3>V. Terms of delivery</h3>
        <p>
          1. METHOD OF DELIVERY OF THE PRODUCT. Immediately after paying the
          purchase price, you will be redirected to a page where you will be
          able to download your set.
        </p>
        <p>
          2. DELIVERY TIME. The downloadable set will be available immediately
          after payment.
        </p>
        <p>
          3. SHIPPING COSTS. Due to the nature of the product, there are no
          shipping costs.
        </p>
        <p>
          4. Digital content requires that you have the hardware and software to
          open and work with PNG files and an internet browser to be fully
          functional.
        </p>
        <h3>
          VI. Data on the functionality of the digital content and its
          interaction with hardware and software, user account
        </h3>
        <p>
          1. I make the digital content available to you, as the Buyer, on the
          basis of your access data only by making available the address of the
          website where the content is located. To be fully functional, the
          Digital Content requires that you have the hardware and software to be
          able to open and work with documents in .PNG format. I am not
          responsible for the unavailability of the content in the event of a
          malfunction or slow speed of your internet connection. Content may be
          temporarily unavailable due to data maintenance or server outages. The
          website may be updated without prior notice.
        </p>
        <p>
          2. The digital content, i.e. the set that is downloaded by you, is
          freely usable for any of your purposes, including commercial purposes.
        </p>
        <p>3. USER ACCOUNT. For the moment we do not allow a user account.</p>
        <h3>VII. Withdrawal from the Purchase Agreement</h3>
        <p>
          1. The subject of the purchase contract is the delivery of digital
          content. As the Buyer, by submitting an order and checking the consent
          to these TOS, you expressly consent to the digital content being
          delivered to you before the expiry of the withdrawal period. Please
          note that in this case you have no right of withdrawal (§ 1837 (l) of
          the Czech Civil Code).
        </p>
        <p>
          2. In the event that technical problems occur during the purchase of
          the set, and payment is made but the set is not downloaded, please
          contact support@sizefire.com so that we can provide the set again or
          refund your money.
        </p>
        <h3>VIII. Warranty, rights under defective performance, claims</h3>
        <p>
          1. The rights arising from defective performance are governed by the
          applicable legal and statutory provisions, in particular § 1914 to
          1925, § 2099 to 2112 and § 2165 to 2174 of the Czech Civil Code.
        </p>
        <p>
          2. The rights arising from the guarantee are governed in particular by
          the provisions of § 2113 to 2117 and § 2161 to 2164 of the Czech Civil
          Code.
        </p>
        <p>
          3. As the Seller, I answer to you that the product (set) is free from
          defects upon receipt. If you are a consumer, then if the defect
          manifests itself within 6 months of receipt, the product shall be
          deemed to have been defective upon receipt. Irrespective of whether
          you are a consumer, I am also responsible for the fact that the
          defects do not appear within the warranty period, which is 24 months
          from receipt of the product.
        </p>
        <p>
          4. In the event of a defect that cannot be rectified, or in the event
          of a recurrence of a defect after repair, or in the event of multiple
          defects, you have the right to request replacement of the product with
          a new one (which in our case would mean re-generating a set for you)
          or to withdraw from the contract. In the case of a remediable defect
          in a product not yet used, you may demand the removal of the defect by
          set re-generating or a reasonable discount on the purchase price or,
          if this is not unreasonable, demand the replacement of the set with a
          new one. If you do not withdraw from the contract or do not exercise
          your right to have a new set delivered without defects or to have a
          part replaced or repaired, you may claim a reasonable discount.
        </p>
        <p>
          5. You will not be entitled to defective performance rights if you
          knew the set was defective before you took delivery of the set or if
          you caused the defect. Warranty and liability claims do not apply to
          defects caused by improper use of the set.
        </p>
        <p>
          6. If the set has not been delivered to you within the delivery time,
          please check your "bulk mail" or spam folders first. If you do not
          find the set there either, make a claim according to the following
          paragraph.
        </p>
        <p>
          7. Please file a claim with me without undue delay after discovering
          the defects. You may file a claim by emailing me at my email address
          support@sizefire.com, or by sending a written claim to the address
          listed in Article II of the TOS. It is appreciated if you include the
          invoice or other proof of purchase, a description of the defect
          claimed and a proposal for resolving the claim. We will deal with the
          complaint without undue delay, within 30 days at the latest, unless we
          expressly agree otherwise. I will provide you with written
          confirmation of the claim and its settlement.
        </p>
        <h3>IX. Handling complaints, resolving consumer disputes</h3>
        <p>
          1. If you have a complaint about the concluded purchase contract, its
          performance or our activities, please contact me at the address
          specified in Article II. TOS or electronically at
          support@sizefire.com.
        </p>
        <p>
          2. I operate my business on the basis of a trade licence, the
          controlling authority is the competent trade licensing authority,
          supervision over compliance with consumer protection regulations is
          carried out by the Czech Trade Inspection Authority. Compliance with
          the data protection regulations is supervised by the Office for
          Personal Data Protection. You can also contact these authorities with
          your complaints.
        </p>
        <p>
          3. If a consumer dispute arises between us as the seller and the
          consumer, the consumer has the right to an out-of-court settlement.
          The Czech Trade Inspection Authority is the subject of the
          out-of-court settlement pursuant to Act No. 634/1992 Coll., on
          Consumer Protection. All the details of the out-of-court settlement
          are given on the website of the Czech Trade Inspection Authority
          www.coi.cz.
        </p>
        <p>
          4. Please note that we are not bound by any codes of conduct (§ 1826
          (1) (e) of the Czech Commercial Code).
        </p>
        <h3>X. Protection of Intellectual Property</h3>
        <p>
          1. PROTECTION OF THE BUYER'S CONTENT BY THE SELLER. Image files and
          all text content that is inserted into the banner set by the Buyer is
          protected by the Seller and won't be used or displayed on Sizefire by
          other Buyers or third parties and won't be used in other banner sets.
          This also applies to the use of the content (purchased banner set) as
          a whole. However, the Seller reserves the right to store and analyse
          created banner set by the Seller for the necessary period of time as
          part of a possible complaint resolution or as technical basis for the
          improvement of the resize of other sets. Such technical information do
          not include the content itself as a whole, but only the metadata, e.g.
          the coordinates or dimensions of individual elements on individual
          banner sizes. If the buyer expresses a need to delete content they
          have created and that is stored on Sizefire, please have them contact
          support@sizefire.com and their content will be deleted. The transfer
          of any data referred to in this paragraph to third parties or other
          Buyers by the Seller is not permitted.
        </p>
        <p>
          2. INTELLECTUAL PROPERTY OF THE OFFERED BANNER SETS. The Seller
          undertakes that all the sets offered on Sizefire.com are the original
          property of the Seller and, if purchased, without or with
          modifications made to them by Buyer become new banner sets, which the
          Buyer may fully and freely dispose of, i.e. use, copy, modify, edit,
          publish, for any purpose, including for commercial purposes.
        </p>
        <h3>XI. Final provisions</h3>
        <p>
          1. The Purchase Contract is concluded for a definite period of time,
          until the obligations of the Seller and the Buyer have been fulfilled.
        </p>
        <p>
          2. The protection of personal data is dealt with in a separate
          document, which can be found here.
        </p>
        <p>
          3. Please note that I am entitled to unilaterally change these TOS,
          but the text of the terms and conditions in force at the time of
          placing the order shall always apply to the Buyer.
        </p>
        <p>4. These TOS are effective from 1 May 2023.</p>
      </div>
    </div>
  );
}

export default Conditions;
