import { Link } from "react-router-dom";

function Navigation() {
  return (
    <nav className="footer_nav">
      <ul>
        <li>
          <Link to="/contact">Contact Us</Link>
        </li>
        <li>
          <Link to="/privacy">Privacy & Policy</Link>
        </li>
        <li>
          <Link to="/conditions">Terms & Conditions</Link>
        </li>
        <li>
          <Link to="/Faq">FAQ</Link>
        </li>
        <li>
          <Link to="/" data-cc="c-settings">
            Cookie preferences
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
