import Navigation from "./Navigation";
import SubNavigation from "./SubNavigation";
import union from "../images/Union.svg";

function Footer() {
  return (
    <footer>
      <div className="footer_section">
        <div className="footer_description">
          <img alt="SizeFire logo" src={union}></img>
          <h3>WHY SIZEFIRE?</h3>
          <p>
            Sizefire is a tool that allows you to quickly create an entire
            banner set for Google display network when you need it most.
          </p>
          <p>
            Choose from our predefined templates and save time and money! The
            first global online tool for automating banner graphics.
          </p>
        </div>
        <div className="footer_colums_wrapper">
          <div className="footer_column">
            <h3>Navigation</h3>
            <SubNavigation />
          </div>
          <div className="footer_column">
            <h3>About</h3>
            <Navigation />
          </div>
          <div className="footer_column">
            <h3>Stay Connected</h3>
            <div className="footer_social">
              <ul>
                <li>
                  <a href="https://www.facebook.com/sizefire" target="_blank">
                    <img
                      alt="Facebook icon"
                      src={require("../images/icon_facebook.png")}
                      className="social_icons"
                    ></img>
                    Facebook
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/sizefire" target="_blank">
                    <img
                      alt="X icon"
                      src={require("../images/icon_x.png")}
                      className="social_icons"
                    ></img>
                    X
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/sizefire" target="_blank">
                    <img
                      alt="Instagram icon"
                      src={require("../images/icon_instagram.png")}
                      className="social_icons"
                    ></img>
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_copyright">
        <p>Copyright© 2023. Sizefire.com</p>
      </div>
      <div className="footer_copyright">
        <img
          alt="GoPay"
          src={require("../images/pay1.png")}
          className="gopayImg"
        ></img>
        <img
          alt="GoPay"
          src={require("../images/pay2.png")}
          className="gopayImg"
        ></img>
        <img
          alt="Visa"
          src={require("../images/pay3.png")}
          className="gopayImg"
        ></img>
        <img
          alt="Visa"
          src={require("../images/pay4.png")}
          className="gopayImg"
        ></img>
        <img
          alt="Visa"
          src={require("../images/pay5.png")}
          className="gopayImg"
        ></img>
        <img
          alt="MasterCard"
          src={require("../images/pay6.png")}
          className="gopayImg"
        ></img>
      </div>
    </footer>
  );
}

export default Footer;
