import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { useEffect } from "react";

function Privacy() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window);
  }, []);

  return (
    <div className="Privacy">
      <Helmet>
        <title>Sizefire | Privacy</title>
      </Helmet>
      <div className="Obsah">
        <h1>Privacy & Policy: Personal data protection</h1>
        <br />
        <h3>I. Content and purpose of the document</h3>
        <p>
          1. This document contains information regarding the protection of
          personal data provided by visitors to the www.sizefire.com website,
          our clients and those interested in our services and products.
        </p>
        <p>
          2. The purpose of this document is to inform you (data subjects) of
          your rights and to provide clear information on how personal data will
          be handled.
        </p>
        <p>
          3. We value your trust in providing us with your personal data and
          therefore we proceed with the processing of personal data not only in
          accordance with Act No. 110/2019 Coll., on the Protection of Personal
          Data applicable to the Czech Republic, but we also adapt the handling
          of personal data to Regulation (EU) 2016/679 , i.e. the General Data
          Protection Regulation, which comes into force on 25 May 2018 and is
          commonly known by the acronym GDPR. Clicking on the number of the
          regulation in the previous sentence will take you directly to the text
          of this regulation and you will also find there the exact legal
          provisions that we refer to in this text below.
        </p>
        <p>
          4. The content that awaits you in this document: 1. Content and
          purpose of the document 2. The data controller - who are we and how
          can you contact us? 3. What personal data do we process and how do we
          obtain it? 4. For what purposes do we process personal data, for how
          long and what entitles us to do so? 5. Disclosure of personal data to
          others 6. Information about your data protection rights 7. Other
          important information for exercising your rights
        </p>
        <h3>II. Data controller - who are we and how can you contact us?</h3>
        <p>
          Ing. Jan Tichavský, ID No.: 08671231, with registered office at
          Hrubého 1202/8, 182 00, Prague 8 - Kobylisy (hereinafter referred to
          as "Controller") I am registered in the Trade Register contact e-mail:
          support@sizefire.com, website: www.sizefire.com
        </p>
        <h3>III. What personal data do we process and how do we obtain it?</h3>
        <p>
          We process the data you provide to us. In specific cases, this may
          include the provision of data by filling in one of the forms on the
          website, by entering data into a user account set up on our website,
          by providing data in the preparation of contracts and related
          documents and in connection with the execution of contracts (i.e. in
          connection with the delivery of goods, provision of services), by
          personal contact, by telephone, in writing, by e-mail or by other
          means of communication (text messages, messages in applications such
          as skype, FB messenger). If we need your consent to process certain
          personal data for specific processing purposes, we will only process
          such data for that purpose with your consent. You provide us with the
          data voluntarily as a matter of principle, only in some cases we would
          not be able to deliver the ordered goods to you without the provision
          of the data (e.g. if you do not manage to download and have not
          provided your contact address), we will always notify you of this in
          advance. You are only obliged to provide us with data where this is
          directly required by law. Personal data can only fall under the
          category of "ordinary personal data", we do not process sensitive
          data, for the processing of which the law imposes stricter conditions.
        </p>
        <p>
          A. Ordinary personal data we process: Name, surname, title, address,
          ID number, telephone number, email, IP address, cookies, information
          about the goods and services you have ordered, information about what
          goods you have bought from us, information about what services we have
          provided to you.
        </p>
        <p>
          B. Special categories of personal data ("sensitive" personal data)
          that we process: We do not process any special categories of personal
          data.
        </p>
        <h3>
          IV. For what purposes do we process personal data, for how long and
          what authorizes us to do so?
        </h3>
        <p>
          A. Processing of personal data for the purpose of concluding a
          contract and fulfilling contractual obligations In order to enter into
          a contract with you and to deliver the goods/products or services you
          have ordered and to conduct related communication with you, we may
          process the following common personal data: name, surname, address,
          e-mail, VAT number, telephone number. The legal title (authorisation)
          for the processing of this data is directly for the performance of
          contractual obligations under the contract concluded between us. This
          does not have to be a contract concluded in a traditional printed
          form, signed in manuscript. For example, it may also be a contract
          concluded verbally, over the telephone or by filling in and submitting
          an order form on the website and our confirmation of such order. For
          this purpose, we process personal data for the duration of the
          contractual relationship between us. After the end of the contractual
          relationship, some data is then retained for the purposes of
          fulfilling legal obligations or for legitimate interest purposes, as
          you will read in the following sections of this document.
        </p>
        <p>
          B. Processing of personal data for compliance with accounting, tax and
          other legal obligations In order to comply with the obligations
          arising from applicable legislation, in particular in the field of
          accounting, tax law and archiving, we may process the following data:
          name, surname, address, registration number, VAT number. The period of
          time for which the data is processed is determined directly by the
          relevant legislation that imposes the obligation to process it on us.
        </p>
        <p>
          C. Processing of personal data for the purposes of our legitimate
          interests or those of a third party Legitimate interests may cover a
          range of situations. We therefore inform you of the legitimate
          interests for which we process personal data: - The legitimate
          interest is to protect and prove our rights and legal claims, in
          particular those arising from contracts entered into or damage caused.
          For these purposes, we process personal data for a period of 4 years
          after the end of the contractual cooperation or our last contact if no
          contract has been concluded. This period is set in relation to the
          limitation periods for claims, taking into account that we may not
          become aware of any claim brought before a court immediately when it
          is brought by the other party. For these purposes, data from contracts
          and our communications with each other are stored. - Furthermore,
          direct marketing is a legitimate interest. For sending commercial
          communications we will process the following personal data of our
          clients. You can always simply stop the sending of commercial
          communications to your email by clicking on the link provided in the
          email. - In order to provide you with tailored products and services
          and to send you only offers and information that are not overwhelming
          and are beneficial to you, we have divided our database of contacts
          and personal data into several lists. For example, if you would like
          to receive information about our newsletter, your email contact will
          automatically be included in our "newsletter" database. Similarly,
          when you send us an order from the web, your contact is included in
          the "ordered" list and, when paid, in the "paid" list, at which point
          the system will also automatically send you the electronic product you
          ordered (or it's instructions for shipping the goods, etc.). Part of
          this automated processing is thus used directly for the fulfilment of
          our contractual obligations, part is used for normal marketing (i.e.
          falls under the category of legitimate interest). If this "sorting" of
          data is carried out on a large scale, in a very "specialised" way,
          then we could only carry it out on the basis of your consent (which
          you can then withdraw at any time, as described in the next section of
          this document).
        </p>
        <p>
          D. Processing of personal data on the basis of your consent If you
          give us your consent, we will process your personal data to send you
          offers of our services/products. We will need your consent for this
          processing if you are not our client. Before you give us your consent,
          we will inform you of what data and for what specific purpose the
          processing will relate to. You can withdraw your consent at any time.
          However, if we also process some of your personal data on the basis of
          another legal basis (see A to C above), we will continue to process
          your personal data for those purposes after your consent has been
          withdrawn, as consent is not required for those specific purposes.
        </p>
        <h3>V. Disclosure of personal data to others</h3>
        <p>
          A. Right of access to personal data This is the right to confirm
          whether we are processing your personal data and, if so, to access
          that data and information about its processing.
        </p>
        <p>
          B. The right to rectification of personal data This is the right to
          have inaccurate personal data relating to you rectified by us without
          undue delay. Taking into account the purposes of the processing, you
          have the right to have incomplete personal data completed, including
          by providing an additional declaration (in which you provide complete
          data).
        </p>
        <p>
          C. Right to erasure of personal data (right to be forgotten) In the
          cases provided for by law or the GDPR, you have the right to request
          that we delete your personal data without undue delay (the GDPR lists
          the grounds in Article 17, including specifying the exceptions where
          deletion will not be carried out).
        </p>
        <p>
          D. Right to restriction of processing In the cases set out in Article
          18 of the GDPR, you have the right to request that we restrict the
          processing of your personal data.
        </p>
        <p>
          E. Right to data portability Under the conditions set out in Article
          20 of the GDPR, you have the right to obtain your personal data and
          transfer it to another controller. If technically feasible, you have
          the right to request the transfer directly to another controller.
        </p>
        <p>
          F. Right to object Where we process personal data for legitimate
          interest purposes, you have the right to object to such processing and
          we will then no longer process the data unless our legitimate interest
          overrides your interests or rights and freedoms. If the legitimate
          interest is direct marketing, then objecting will always result in the
          cessation of further processing for direct marketing purposes.
        </p>
        <p>
          G. Right to lodge a complaint with a supervisory authority If you
          believe that your data protection rights are being infringed, you have
          the right to lodge a complaint with the Data Protection Authority.
          Further information about the Authority and data protection can be
          found directly on the Authority's website at www.uoou.cz.
        </p>
        <h3>VII. Other important information for exercising your rights</h3>
        <p>
          If you have any further questions about the processing of your
          personal data by us, you can contact us at support@sizefire.com. By
          sending a message to this email or by sending a written request to our
          address listed at the beginning of this document, you can also
          directly exercise your rights, which we describe in Article VI. Please
          note that we may contact you afterwards to verify that the request is
          indeed made directly by you and to reasonably verify your identity and
          request. The same applies to any telephone or similar communications.
          The current version of this document can always be found at
          www.sizefire.com. This version is effective from 1 April 2023.
        </p>
      </div>
    </div>
  );
}

export default Privacy;
