import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { useEffect } from "react";

function ThankYou() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window);
  }, []);

  return (
    <div className="ThankYou">
      <Helmet>
        <title>Sizefire | Downloaded</title>
      </Helmet>
      <div className="Obsah">
        <h1>Your banners are downloaded!</h1>
        <p>We are happy Sizefire is helpful to you.</p>
        <p>
          If there is anything you would like to add into our aplication? Please
          let us know at{" "}
          <a href="mailto:jirka@sizefire.com">jirka@sizefire.com</a>.
        </p>
        <br />
        <Link to="/">
          <button>Back to homepage</button>
        </Link>
      </div>
    </div>
  );
}

export default ThankYou;
