import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { useEffect } from "react";

function Pricing() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window);
  }, []);

  return (
    <div className="Pricing">
      <Helmet>
        <title>Sizefire | Pricing</title>
      </Helmet>
      <div className="Obsah">
        <h1>Pricing</h1>
        <p>
          For now we are offering one time payment for Google Ads banner set
          download of 10 sizes (120x600, 160x600, 200x200, 250x250, 300x250,
          300x600, 336x280, 468x60, 728x90, and 970x90). All of these sizes are
          provided as PNG downloads together as a package for price of 1 USD.
        </p>
      </div>
    </div>
  );
}

export default Pricing;
