import styled from "styled-components";

export const Row = styled.div`
  display: flex;
`;

export const Collumn = styled.div`
  display: flex;
  flex-direction: column;
`;
