import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { useEffect } from "react";

function Faq() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window);
  }, []);

  return (
    <div className="Faq">
      <Helmet>
        <title>Sizefire | FAQ</title>
      </Helmet>
      <div className="Obsah">
        <h1>FAQ</h1>
        <h3>How do I pay the premium?</h3>
        <p>
          To set your premium account and pay for premium, click "buy premium"
          button on top left side of the screen. Then fill your information and
          confirm your registration in your mail.
        </p>
      </div>
    </div>
  );
}

export default Faq;
