import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import { useEffect } from "react";

function PageNotFound() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window);
  }, []);

  return (
    <div className="PageNotFound">
      <Helmet>
        <title>Sizefire | Not Found</title>
      </Helmet>
      <div className="Obsah">
        <h1>404 page not found</h1>
        <p>
          We are sorry, but the page you are looking for does not seem to exist.
        </p>
        <br />
        <Link to="/">
          <button>Back to homepage</button>
        </Link>
      </div>
    </div>
  );
}

export default PageNotFound;
