import { Link } from "react-router-dom";

function SubNavigation() {
  return (
    <nav className="footer_nav">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <a href="https://www.sizefire.com/gallery/">Gallery</a>
        </li>
        {/*<li>
          <Link to="/pricing">Pricing</Link>
        </li>*/}
        <li>
          <Link to="/aboutus">About Us</Link>
        </li>
      </ul>
    </nav>
  );
}

export default SubNavigation;
