import { Link } from "react-router-dom";

function MainNavigation() {
  return (
    <nav>
      <Link to="/">Home</Link>
      <a href="https://www.sizefire.com/gallery/">Gallery</a>
      {/*<Link to="/pricing">Pricing</Link>*/}
      <Link to="/aboutus">About Us</Link>
    </nav>
  );
}

export default MainNavigation;
