import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { useEffect } from "react";

function Contact() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window);
  }, []);

  return (
    <div className="Contact">
      <Helmet>
        <title>Sizefire | Contact</title>
      </Helmet>
      <div className="Obsah">
        <h1>Contact Us</h1>
        <p>
          Sizefire service is substituted by Ing. Jan Tichavský ID: 08671231
          Place of business Hrubého 1202/8, 182 00, Praha 8 - Kobylisy Delivery
          address: Hrubého 1202/8, 182 00, Praha 8 - Kobylisy E-mail delivery
          address: support@sizefire.com. Phone: +420775279193. Do you have any
          questions? Please let us know on support@sizefire.com
        </p>
      </div>
    </div>
  );
}

export default Contact;
