function Card(props) {
  const number = props.data_no;
  const title = props.data_title;
  const text = props.data_text;

  return (
    <div className="Card">
      <h1>{number}</h1>
      <div className="Card_inner">
        <div className="Card_circle"></div>
        <img
          alt={`step ${number} image`}
          src={require(`../images/${number}.svg`)}
        />
        <div className="Card_inner_text">
          <h4>{title}</h4>
          {text}
        </div>
      </div>
    </div>
  );
}

export default Card;
