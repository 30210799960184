import Card from "./Card";
//import Form from "./Form";
import SmallGallery from "./SmallGallery";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { useEffect } from "react";

function Home() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Sizefire | Home</title>
      </Helmet>
      <div className="Home">
        <div className="Home-left">
          <h1>FREE BANNERS FOR YOUR BUSINESS!</h1>
          <p>
            Get complete Google Ads banner set. Fast. No skills required. Save
            hours of work! Only change text, photos and let's download!
          </p>
          <a href="https://www.sizefire.com/gallery">
            <button>Create Banners NOW!</button>
          </a>
        </div>
        <div className="Home-right">
          <img
            alt="Set preview"
            src={require("../images/set_preview1.png")}
          ></img>
        </div>
      </div>
      <div className="Obsah">
        <h1>10 sizes, 80+ templates</h1>
        <p>
          Choose from 80+ unique designs to edit and generate 10 sizes for your
          display ad campaign!
        </p>
        <div>
          <img
            className="Obsah-image"
            alt="Sizes preview"
            src={require("../images/preview_sizes.png")}
          ></img>
        </div>
      </div>
      <div className="Obsah">
        <h2>How It Works</h2>
        <p>
          Choose a design, make minor adjustments and then generate the entire
          set in JPEG in a matter of moments.
        </p>
        <div className="Cards">
          <Card
            id="Card1"
            data_no="1"
            data_title="Choose Design"
            data_text="Choose from our ready-made designs, which are inspired by the actually used visuals from the real life."
          />
          <Card
            data_no="2"
            data_title="Change text & photos"
            data_text="The designs are ready for easy editing. Rewrite text, replace images and colors. Adjust slightly."
          />
          <Card
            data_no="3"
            data_title="Download for free"
            data_text="Download entire set of banners for social and display advertisment in PNG format. All&nbsp;for free!"
          />
        </div>
      </div>
      {/*<Form />*/}
      <SmallGallery />
    </div>
  );
}

export default Home;
