import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { useEffect } from "react";

function AboutUs() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window);
  }, []);

  return (
    <div className="AboutUs">
      <Helmet>
        <title>Sizefire | About Us</title>
      </Helmet>
      <div className="Obsah">
        <h1>About Us</h1>
        <p>
          We are SW developers that dream of world with no manual work for
          graphic designers. We build a fully automatic AI tool which as we
          believe will let designers focus solely on creativity. With our
          technology we are going to transform how people interact with
          graphical design and how graphical design interacts with them bringing
          intelligence into it.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
