import "./styles/extra-small.css";
import "./styles/small.css";
import "./styles/medium.css";
import "./styles/large.css";
import "./styles/extra-large.css";

import union from "./images/Union.svg";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import ScrollToTop from "./ScrollToTop";
import Home from "./components/Home";
import Pricing from "./components/Pricing";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";
import Privacy from "./components/Privacy";
import Conditions from "./components/Conditions";
import Faq from "./components/Faq";
import ThankYou from "./components/ThankYou";
import Footer from "./components/Footer";
import PageNotFound from "./components/PageNotFound";
import MainNavigation from "./components/MainNavigation";

import ReactGA from "react-ga";
const TRACKING_ID = "G-CWLFEQ0DFT"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const [clicked, setClicked] = useState(false);
  const [display, setDisplay] = useState("hide");

  const handleClick = () => {
    if (!clicked) {
      setDisplay("show");
    } else {
      setDisplay("hide");
    }
    setClicked(!clicked);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <div className="Header">
          <Link to="/">
            <img id="header-logo" alt="SizeFire logo" src={union}></img>
          </Link>
          <div className="full-navigation">
            <MainNavigation />
          </div>
          <img
            id="menu"
            src={require("./images/menu.png")}
            alt="menu"
            onClick={handleClick}
          />
        </div>
        <div className={display}>
          <MainNavigation />
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/conditions" element={<Conditions />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/thankyou/downloaded" element={<ThankYou />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
