import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import { Collumn } from "./Common";

type TCategory =
  | "all"
  | "business_services"
  | "cars_and_vehicles"
  | "clothing_and_accessories"
  | "consumer_electronics";

type TBanner = {
  id: number;
  category: string;
  preview: string;
};

function SmallGallery() {
  //const [isDisplayed, setIsDisplayed] = useState(false);
  const [loadedData, setLoadedData] = useState<TBanner[]>([]);

  //hledaná kategorie bannerů
  const [category, setCategory] = useState<TCategory>("all");

  //načtení dat ze serveru
  async function loadData() {
    const response = await fetch(
      "https://sizefire.eu/editor/v1/sets/public?fbclid=IwAR36H3UseMXRMlwrNJfcy3-OujPTH4OSq1syGRyE6cfV5Fkzk0Fb4VOc3LA"
    );
    const data = await response.json();
    setLoadedData(() => data);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="SmallGallery">
      <h2>Gallery</h2>
      <div className="SmallGallery_buttons">
        <div>
          <button onClick={() => setCategory("all")}>Display all</button>
          <button onClick={() => setCategory("business_services")}>
            Business and Services
          </button>
          <button onClick={() => setCategory("cars_and_vehicles")}>
            Cars and Vehicles
          </button>
          <button onClick={() => setCategory("clothing_and_accessories")}>
            Clothing and Accessories
          </button>
          <button onClick={() => setCategory("consumer_electronics")}>
            Consumer and Electronics
          </button>
          <Link to="https://www.sizefire.com/gallery/">
            <button>More</button>
          </Link>
        </div>
      </div>
      <div className="banners">
        {loadedData
          .filter(
            (banner) => category === "all" || banner.category.includes(category)
          )
          .slice(0, 8)
          .map((banner, key) => {
            return (
              <SBanner key={key}>
                <a
                  href={`https://www.sizefire.com/service/?a=service&techTree=TEMPLATE_USER&setId=${banner.id}`}
                >
                  <img src={banner.preview}></img>
                </a>
                <div className="btn-container">
                  <a
                    href={`https://www.sizefire.com/service/?a=service&techTree=TEMPLATE_USER&setId=${banner.id}`}
                  >
                    <button>Use this</button>
                  </a>
                </div>
              </SBanner>
            );
          })}
      </div>
    </div>
  );
}

const SBanner = styled(Collumn)`
  padding: 5px;
`;

export default SmallGallery;
